<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
    data() {
        return {};
    },
    methods: {},
    created() {
    },
};
</script>

<style lang="scss">
@import "./assets/styles/common.scss";
#app {
    width: 100%;
}
</style>
