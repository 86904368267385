import axios from './index'

/* 
 * get
 * @param {路径,参数对象}
 */
const get = async (url, query) => {
    return await axios.get(url, {
        params: query
    });
}

/* 
 * post
 * @param {路径,参数对象}
 */
const post = async (url, query) => {
    return await axios.post(url, query);
}

/* 
 * put
 * @param {路径,参数对象}
 */
const put = async (url, query) => {
    return await axios.put(url, query);
}

/* 
 * delete
 * @param {路径,参数对象}
 */
const Delete = async (url, query) => {
    return await axios.delete(url, query);
}

/* 
 * upload
 * @param {路径,参数对象}
 */
const upload = async (url, file) => {
    let formData = new FormData(); // 创建一个 FormData 对象
    formData.append('file', file); // 将选中的文件添加到 FormData 中
    return await axios.post(url, formData, { // 发送 POST 请求
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}




let Api = {};


/* 
 * 登录
 * @param {*}
 */
Api.login = async (query = {}) => await post("/admin/login", query);

/* 
 * 修改密码
 * @param {*}
 */
Api.modifyPwd = async (query = {}) => await post("/admin/modifyPwd", query);

/**
 * 获取我的好友列表
 * @param {*} query 
 * @returns 
 */
Api.getFriendList = async (query = {}) => await get("/admin/chat/getFriendList", query);

/**
 * 获取消息列表
 * @param {*} query 
 * @returns 
 */
Api.getMessageList = async (query = {}) => await get("/admin/chat/getMessageList", query);

/**
 * 读消息
 * @param {*} query 
 * @returns 
 */
Api.readMessage = async (query = {}) => await get("/admin/chat/readMessage", query);

/**
 * 上传图片
 * @param {*} file 
 * @returns 
 */
Api.uploadImg = async (file = {}) => await upload("/admin/uploadImg", file);


export default {
    get, post, put, delete: Delete, upload, ...Api
}

