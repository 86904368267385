import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import api from './utils/http/api.js';
import utils from './utils/utils.js';
import customUpload from '@/components/custom-upload'

// 阿里巴巴图标库
import './assets/fonts/alibaba/iconfont'

Vue.use(ElementUI);
Vue.use(customUpload);

Vue.prototype.$api = api;
Vue.prototype.$utils = utils;

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app');


