import axios from 'axios'
import router from '@/router'
import store from '@/store';
import { Notification } from 'element-ui';

/**
 * 创建axios实例对象
 */
const instance = axios.create({
    // 公共配置
    baseURL: process.env.VUE_APP_BASE_URL, //请求地址
    timeout: 5000 //超时时间
})

// 请求拦截器
instance.interceptors.request.use(function (config) {
    // 获取token
    if (store.getters._getToken) config.headers.Authorization = store.getters._getToken;
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


// 响应拦截器
instance.interceptors.response.use(success => {
    if (success.status == 200) {
        if ([401, 402, 403, 405, 406, 500, 501].includes(success.data.code)) {
            Notification({
                title: '错误',
                message: success.data.msg,
                duration: 3000,
                showClose: false,
                type: 'error'
            });
            if (success.data.code == 401 || success.data.code == 405) {
                store.commit("set_LoginStatus", false);
                router.push("/login");
            }
        }
    } else {
        Notification({
            title: '错误',
            message: "服务器被吃掉啦！",
            duration: 3000,
            showClose: false,
            type: 'error'
        });
    }
    return success.data;
}, error => {
    if (error.response && error.response.status) {
        if (error.response.status == 404) {
            Notification({
                title: '错误',
                message: "您所访问的资源不存在！",
                duration: 3000,
                showClose: false,
                type: 'error'
            });
        }
        if (error.response.status == 500) {
            Notification({
                title: '错误',
                message: "服务器出错啦！",
                duration: 3000,
                showClose: false,
                type: 'error'
            });
        }
    } else {
        Notification({
            title: '错误',
            message: "服务器出错啦！",
            duration: 3000,
            showClose: false,
            type: 'error'
        });
    }

    return error;
});

export default instance;