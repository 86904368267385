<template>
    <div class="content-admin">
        <!-- 顶部菜单区域 -->
        <div class="menu-box">
            <div class="left">
                <div class="title">后台管理中心</div>
            </div>
            <div class="right">
                <el-dropdown @command="dropdownCommand">
                    <div class="el-dropdown-link">
                        <el-image
                            style="width: 40px; height: 40px"
                            :src="
                                userInfo.avatar
                                    ? baseUrl + userInfo.avatar
                                    : require('@/assets/images/default-avatar.png')
                            "
                        ></el-image>
                        <span >{{ userInfo.nickname }}</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            icon="el-icon-edit-outline"
                            command="0"
                        >
                            <span>修改密码</span>
                        </el-dropdown-item>
                        <el-dropdown-item
                            icon="el-icon-switch-button"
                            command="1"
                        >
                            <span>退出登录</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <!-- 中间区域 -->
        <div class="center">
            <div class="nav-list">
                <el-menu
                    :default-active="defaultActive"
                    background-color="#545c64"
                    text-color="#fff"
                    active-text-color="#ffd04b"
                    router
                >
                    <el-menu-item
                        :index="item.path"
                        v-for="(item, index) in menuList"
                        :key="index"
                    >
                        <span slot="title">{{ item.meta.title }}</span>
                    </el-menu-item>
                </el-menu>
            </div>
            <!-- 内容区域 -->
            <div class="admin-content-box">
                <keep-alive>
                    <router-view />
                </keep-alive>
            </div>
        </div>
        <!-- 修改密码 -->
        <el-dialog
            title="修改密码"
            :visible.sync="pwdDialogVisible"
            width="30%"
        >
            <el-form
                label-position="right"
                label-width="80px"
                :model="pwdForm"
                :rules="pwdRules"
                ref="pwdRuleForm"
            >
                <el-form-item label="原密码" prop="password">
                    <el-input
                        v-model="pwdForm.password"
                        show-password
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                    <el-input
                        v-model="pwdForm.newPassword"
                        show-password
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                    <el-input
                        v-model="pwdForm.confirmPassword"
                        show-password
                        clearable
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="pwdDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="pwdSubmit"
                    >确认修改</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
/**
 * 后台父页面
 */
export default {
    data() {
        return {
            baseUrl: "", //文件服务器地址
            menuList: [], //菜单列表
            defaultActive: "", //默认选中
            pwdDialogVisible: false, //修改密码弹窗
            userInfo: {
                avatar: "",
                nickname: "",
            },
            pwdForm: {
                password: "",
                newPassword: "",
                confirmPassword: "",
            }, // 修改密码表单
            pwdRules: {
                password: [
                    {
                        required: true,
                        message: "请输入原密码",
                        trigger: "blur",
                    },
                ],
                newPassword: [
                    {
                        required: true,
                        message: "请输入新密码",
                        trigger: "blur",
                    },
                    {
                        min: 5,
                        max: 20,
                        message: "长度在 5 到 20 个字符",
                        trigger: "blur",
                    },
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: "请输入确认密码",
                        trigger: "blur",
                    },
                    {
                        min: 5,
                        max: 20,
                        message: "长度在 5 到 20 个字符",
                        trigger: "blur",
                    },
                ],
            }, // 修改密码表单验证规则
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            this.$router.push(key);
        },
        // 下拉点击事件
        dropdownCommand(e) {
            if (e == "0") this.openPwdDialog();
            if (e == "1") this.logout();
        },
        // 打开修改密码对话框
        openPwdDialog() {
            this.pwdDialogVisible = true;
            this.$nextTick(() => {
                this.$refs["pwdRuleForm"].resetFields();
            });
        },
        // 修改密码
        async pwdSubmit() {
            this.$refs["pwdRuleForm"].validate(async (valid) => {
                if (valid) {
                    if (
                        this.pwdForm.newPassword != this.pwdForm.confirmPassword
                    )
                        return this.$message.error("两次输入的密码不一致！");
                    if (this.pwdForm.newPassword == this.pwdForm.password)
                        return this.$message.error(
                            "修改密码与原密码一致，无需修改！"
                        );
                    let res = await this.$api.modifyPwd(this.pwdForm);
                    if (res.code == 200) {
                        this.$notify({
                            title: "成功",
                            message: "修改成功！",
                            duration: 3000,
                            showClose: false,
                            type: "success",
                        });
                        this.logout();
                    }
                }
            });
        },
        // 退出登录
        logout() {
            this.$store.commit("set_token", "");
            this.$router.push("/login");
        },
        //
    },
    watch: {
        $route(to) {
            this.defaultActive = to.path;
        },
    },
    mounted() {
        const routes = this.$router.options.routes;
        const adminRouter = routes.find((obj) => obj.name === "adminIndex");
        this.menuList = adminRouter.children;
        // 获取当前激活的路由地址
        this.defaultActive = this.$route.path;
        this.baseUrl = process.env.VUE_APP_FILE_URL;
        this.userInfo = this.$store.getters._getUserInfo;
    },
};
</script>

<style lang="scss" scoped>
.content-admin {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f7fa;
    box-sizing: border-box;

    .menu-box {
        width: 100%;
        height: 60px;
        background-color: rgb(84, 92, 100);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        box-sizing: border-box;

        .left {
            display: flex;
            align-items: center;

            .title {
                font-size: 20px;
                font-weight: bold;
            }
        }

        .right {
            .el-dropdown-link {
                display: flex;
                align-items: center;
                color: #fff;

                span {
                    margin-left: 10px;
                }

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    padding-right: 0;
                    margin-right: 10px;
                    border: 1px solid #ddd;
                    background-color: #fff;
                }
            }
        }
    }

    .center {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        height: calc(100vh - 60px);

        .nav-list {
            width: 200px;
            border-top: 1px solid #fff;
            box-sizing: border-box;
            background-color: #545c64;
        }

        .admin-content-box {
            width: calc(100% - 200px);
            height: 100%;
            margin: 0 auto;
            padding: 20px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 10px;
            overflow-y: auto;
        }
    }
}
</style>