import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedstate from 'vuex-persistedstate'; //持久化插件

Vue.use(Vuex);//挂载vuex

//创建store实例
const store = new Vuex.Store({
    strict: true, //严格模式开启,无法直接修改state里的值
    state: {
        token: "", //token
        userInfo: {}, // 用户信息
        frontUserInfo: {}, // 前台用户信息
    }, //相当于一个存储空间
    getters: {
        _getToken(state) {
            return state.token;
        },
        _getUserInfo(state) {
            return state.userInfo;
        },
        _getFrontUserInfo(state) {
            return state.frontUserInfo;
        },
    }, //state的计算数据 实现数据过滤的作用（get）
    mutations: {
        set_token(state, param) {
            state.token = param;
        },
        set_userInfo(state, param) {
            state.userInfo = param;
        },
        set_frontUserInfo(state, param) {
            state.frontUserInfo = param;
        },
    },//设置state中的数据（set）
    actions: {},  //可以发送异步请求
    modules: {},  //拆分存储空间模块化
    plugins: [
        createPersistedstate({
            //本地存储名字
            key: 'admin-store',
            //指定需要存储的模块
            paths: ['token', 'userInfo', 'frontUserInfo']
        }),
    ]  //拓展vuex功能
})
//导出store对象
export default store;
