import store from "@/store";

let utils = {
    /**
     * 格式化时间戳
     * @param {*} timestamp 
     * @param {*} format 
     * @returns 
     */
    formatTimestamp: (timestamp, format = 'yyyy-MM-dd HH:mm:ss') => {
        let date = new Date(timestamp);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // 使用 24 小时制
        };
        let formattedDate = format.replace(/yyyy/, date.getFullYear());
        formattedDate = formattedDate.replace(/MM/, String(date.getMonth() + 1).padStart(2, '0'));
        formattedDate = formattedDate.replace(/dd/, String(date.getDate()).padStart(2, '0'));
        formattedDate = formattedDate.replace(/HH/, String(date.getHours()).padStart(2, '0'));
        formattedDate = formattedDate.replace(/mm/, String(date.getMinutes()).padStart(2, '0'));
        formattedDate = formattedDate.replace(/ss/, String(date.getSeconds()).padStart(2, '0'));
        return formattedDate;
    },
    /**
     * 获取uuid
     * @returns 
     */
    getUUID: () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
        })
    }
};

export default utils;